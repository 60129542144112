// **  Initial State
const initialState = {
  depots: [],
  depot: {},
  total: 0,
  createDepotStatus: null,
  createDepotError: false,
  updateDepotStatus: null,
  updateDepotError: false,
  deleteDepotStatus: false,
};

const depotReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ALL_DEPOTS":
      return { ...state, total: action.total, depots: action.payload };
    case "SELECTED_DEPOT":
      return { ...state, depot: action.payload };
    case "CREATE_DEPOT_STATUS":
      return { ...state, createDepotStatus: action.payload };
    case "CREATE_DEPOT_ERROR":
      return { ...state, createDepotError: action.payload };
    case "UPDATE_DEPOT_STATUS":
      return { ...state, updateDepotStatus: action.payload };
    case "UPDATE_DEPOT_ERROR":
      return { ...state, updateDepotError: action.payload };
    case "DELETE_DEPOT_STATUS":
      return { ...state, deleteDepotStatus: action.payload };
    case "CLEAR_DEPOT_STATUS":
      return {
        ...state,
        createDepotStatus: null,
        createDepotError: false,
        updateDepotStatus: null,
        updateDepotError: false,
        deleteDepotStatus: false,
      };
    default:
      return state;
  }
};

export default depotReducer;
