// **  Initial State
const initialState = {
  classes: [],
  class: {},
  total: 0,
  createClassStatus: null,
  createClassError: false,
  updateClassStatus: null,
  updateClassError: false,
  deleteClassStatus: false,
};

const classReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ALL_CLASSES":
      return { ...state, total: action.total, classes: action.payload };
    case "SELECTED_CLASS":
      return { ...state, class: action.payload };
    case "CREATE_CLASS_STATUS":
      return { ...state, createClassStatus: action.payload };
    case "CREATE_CLASS_ERROR":
      return { ...state, createClassError: action.payload };
    case "UPDATE_CLASSL_STATUS":
      return { ...state, updateClassStatus: action.payload };
    case "UPDATE_CLASS_ERROR":
      return { ...state, updateClassError: action.payload };
    case "DELETE_CLASS_STATUS":
      return { ...state, deleteClassStatus: action.payload };
    case "CLEAR_CLASS_STATUS":
      return {
        ...state,
        createClassStatus: null,
        createClassError: false,
        updateClassStatus: null,
        updateClassError: false,
        deleteClassStatus: false,
      };
    default:
      return state;
  }
};

export default classReducer;
