// **  Initial State
const initialState = {
  flow: [],
};

const flowReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ALL_FLOW":
      return { ...state, flow: action.payload };
    case "CLEAR_FLOW_STATUS":
      return {
        ...state,
        initialState,
      };
    default:
      return state;
  }
};

export default flowReducer;
