// **  Initial State
const initialState = {
  orders: [],
  total: 0,
  loading: false,
  createOrderStatus: null,
  createOrderError: false,
  updateOrderStatus: null,
  updateOrderError: false,
  deleteOrderStatus: false,
  deleteFileStatus: "no",
  copyOrder: null,
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ALL_ORDERS":
      return { ...state, total: action.total, orders: action.payload };
    case "SET_LOADING":
      return { ...state, loading: action.payload };
    case "CREATE_ORDER_STATUS":
      return { ...state, createOrderStatus: action.payload };
    case "CREATE_ORDER_ERROR":
      return { ...state, createOrderError: action.payload };
    case "UPDATE_ORDER_STATUS":
      return { ...state, updateOrderStatus: action.payload };
    case "UPDATE_ORDER_ERROR":
      return { ...state, updateOrderError: action.payload };
    case "DELETE_ORDER_STATUS":
      return { ...state, deleteOrderStatus: action.payload };
    case "DELETE_FILE_STATUS":
      return { ...state, deleteFileStatus: action.payload };
    case "COPY_ORDER":
      return { ...state, copyOrder: action.payload };
    case "CLEAR_ORDER_STATUS":
      return {
        ...state,
        createOrderStatus: null,
        createOrderError: false,
        updateOrderStatus: null,
        updateOrderError: false,
        deleteOrderStatus: false,
        deleteFileStatus: "no",
        copyOrder: null,
      };
    default:
      return state;
  }
};

export default orderReducer;
