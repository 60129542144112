// **  Initial State
const initialState = {
  users: [],
  user: {},
  total: 0,
  createUserStatus: null,
  createUserError: false,
  updateUserStatus: null,
  updateUserError: false,
  deleteUserStatus: null,
};
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ALL_USERS":
      return { ...state, total: action.total, users: action.payload };
    case "SINGLE_USER":
      return { ...state, user: action.payload };
    case "CREATE_USER_STATUS":
      return { ...state, createUserStatus: action.payload };
    case "CREATE_USER_ERROR":
      return { ...state, createUserError: action.payload };
    case "UPDATE_USER_STATUS":
      return { ...state, updateUserStatus: action.payload };
    case "UPDATE_USER_ERROR":
      return { ...state, updateUserError: action.payload };
    case "DELETE_USER_STATUS":
      return { ...state, deleteUserStatus: action.payload };

    case "CLEAR_USER_STATUS":
      return {
        ...state,
        deleteUserStatus: false,
        createUserStatus: false,
        updateUserStatus: false,
        createUserError: false,
      };
    default:
      return state;
  }
};

export default userReducer;
