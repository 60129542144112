// ** Logo
import logo from "@src/assets/images/logo/signaturel_logo_small.png";

const SpinnerComponent = () => {
  return (
    <div className="fallback-spinner vh-100">
      <img className="fallback-logo" src={logo} width="100px" alt="logo" />
      <div className="loading" style={{marginLeft: 15}}>
        <div className="effect-1 effects"></div>
        <div className="effect-2 effects"></div>
        <div className="effect-3 effects"></div>
      </div>
    </div>
  );
};

export default SpinnerComponent;
