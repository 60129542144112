// **  Initial State
const initialState = {
  notifications: [],
  total: 0,
  updateNotificationStatus: null,
  updateNotificationError: false,
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "NOTIFICATIONS":
      return { ...state, total: action.total, notifications: action.payload };
    case "UPDATE_NOTIFICATION_STATUS":
      return { ...state, updateNotificationStatus: action.payload };
    case "UPDATE_NOTIFICATION_ERROR":
      return { ...state, updateNotificationError: action.payload };
    case "CLEAR_NOTIFICATION_STATUS":
      return {
        ...state,
        initialState,
      };
    default:
      return state;
  }
};

export default notificationReducer;
