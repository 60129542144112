// **  Initial State
const initialState = {
  units: [],
  unit: {},
  total: 0,
  createUnitStatus: null,
  createUnitError: false,
  updateUnitStatus: null,
  updateUnitError: false,
  deleteUnitStatus: false,
};

const classReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ALL_UNITS":
      return { ...state, total: action.total, units: action.payload };
    case "SELECTED_UNIT":
      return { ...state, unit: action.payload };
    case "CREATE_UNIT_STATUS":
      return { ...state, createUnitStatus: action.payload };
    case "CREATE_UNIT_ERROR":
      return { ...state, createUnitError: action.payload };
    case "UPDATE_UNIT_STATUS":
      return { ...state, updateUnitStatus: action.payload };
    case "UPDATE_UNIT_ERROR":
      return { ...state, updateUnitError: action.payload };
    case "DELETE_UNIT_STATUS":
      return { ...state, deleteUnitStatus: action.payload };
    case "CLEAR_UNIT_STATUS":
      return {
        ...state,
        createUnitStatus: null,
        createUnitError: false,
        updateUnitStatus: null,
        updateUnitError: false,
        deleteUnitStatus: false,
      };
    default:
      return state;
  }
};

export default classReducer;
