// **  Initial State
const initialState = {
  warehouses: [],
  warehouse: {},
  total: 0,
  createWarehouseStatus: null,
  createWarehouseError: false,
  updateWarehouseStatus: null,
  updateWarehouseError: false,
  deleteWarehouseStatus: false,
};

const warehouseReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ALL_WAREHOUSES":
      return { ...state, total: action.total, warehouses: action.payload };
    case "SELECTED_WAREHOUSES":
      return { ...state, depot: warehouse.payload };
    case "CREATE_WAREHOUSE_STATUS":
      return { ...state, createWarehouseStatus: action.payload };
    case "CREATE_WAREHOUSE_ERROR":
      return { ...state, createWarehouseError: action.payload };
    case "UPDATE_WAREHOUSE_STATUS":
      return { ...state, updateWarehouseStatus: action.payload };
    case "UPDATE_WAREHOUSE_ERROR":
      return { ...state, updateWarehouseError: action.payload };
    case "DELETE_WAREHOUSE_STATUS":
      return { ...state, deleteWarehouseStatus: action.payload };
    case "CLEAR_WAREHOUSE_STATUS":
      return {
        ...state,
        createWarehouseStatus: null,
        createWarehouseError: false,
        updateWarehouseStatus: null,
        updateWarehouseError: false,
        deleteWarehouseStatus: false,
      };
    default:
      return state;
  }
};

export default warehouseReducer;
