// **  Initial State
const initialState = {
  products: [],
  product: {},
  total: 0,
  createProductStatus: null,
  createProductError: false,
  updateProductStatus: null,
  updateProductError: false,
  deleteProductStatus: false,
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ALL_PRODUCTS":
      return { ...state, total: action.total, products: action.payload };
    case "SELECTED_PRODUCT":
      return { ...state, product: action.payload };
    case "CREATE_PRODUCT_STATUS":
      return { ...state, createProductStatus: action.payload };
    case "CREATE_PRODUCT_ERROR":
      return { ...state, createProductError: action.payload };
    case "UPDATE_PRODUCT_STATUS":
      return { ...state, updateProductStatus: action.payload };
    case "UPDATE_PRODUCT_ERROR":
      return { ...state, updateProductError: action.payload };
    case "DELETE_PRODUCT_STATUS":
      return { ...state, deleteProductStatus: action.payload };
    case "CLEAR_PRODUCT_STATUS":
      return {
        ...state,
        deleteProductStatus: false,
        createProductStatus: false,
        updateProductStatus: false,
        createProductError: false,
      };
    default:
      return state;
  }
};

export default productReducer;
