// **  Initial State
const initialState = {
  logs: [],
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGS":
      return { ...state, logs: action.payload };
    case "CLEAR_LOG_STATUS":
      return {
        ...state,
        initialState,
      };
    default:
      return state;
  }
};

export default orderReducer;
