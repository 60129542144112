// **  Initial State
const initialState = {
  movements: [],
  movement: {},
  total: 0,
  createMovementStatus: null,
  createMovementError: false,
};

const classReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ALL_MOVEMENTS":
      return { ...state, total: action.total, movements: action.payload };
    case "SELECTED_MOVEMENT":
      return { ...state, movement: action.payload };
    case "CREATE_MOVEMENT_STATUS":
      return { ...state, createMovementStatus: action.payload };
    case "CREATE_MOVEMENT_ERROR":
      return { ...state, createMovementError: action.payload };
    case "CLEAR_MOVEMENT_STATUS":
      return {
        ...state,
        createMovementStatus: null,
        createMovementError: false,
      };
    default:
      return state;
  }
};

export default classReducer;
