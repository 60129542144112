// **  Initial State
const initialState = {
  materials: [],
  material: {},
  total: 0,
  createMaterialStatus: null,
  createMaterialError: false,
  updateMaterialStatus: null,
  updateMaterialError: false,
  deleteMaterialStatus: false,
};

const materialReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ALL_MATERIALS":
      return { ...state, total: action.total, materials: action.payload };
    case "SELECTED_MATERIAL":
      return { ...state, material: action.payload };
    case "CREATE_MATERIAL_STATUS":
      return { ...state, createMaterialStatus: action.payload };
    case "CREATE_MATERIAL_ERROR":
      return { ...state, createMaterialError: action.payload };
    case "UPDATE_MATERIAL_STATUS":
      return { ...state, updateMaterialStatus: action.payload };
    case "UPDATE_MATERIAL_ERROR":
      return { ...state, updateMaterialError: action.payload };
    case "DELETE_MATERIAL_STATUS":
      return { ...state, deleteMaterialStatus: action.payload };
    case "CLEAR_MATERIAL_STATUS":
      return {
        ...state,
        createMaterialStatus: null,
        createMaterialError: false,
        updateMaterialStatus: null,
        updateMaterialError: false,
        deleteMaterialStatus: false,
      };
    default:
      return state;
  }
};

export default materialReducer;
