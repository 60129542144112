// **  Initial State
const initialState = {
  stocks: [],
  total: 0,
};

const stocksReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ALL_STOCKS":
      return { ...state, total: action.total, stocks: action.payload };
    case "CLEAR_STOCK_STATUS":
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default stocksReducer;
